@import "../../scss/variables";

.auth_loader {
  color: $color-primary-base;

  &__lock {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}
