@import "../../scss/variables";

.dashboard__units {
  color: $color-secondary-lighten-50;
}

.img--iron {
  max-width: 30px;
}

.img--golf-flag {
  max-width: 200px;
}
