@import "./scss/variables";

body {
  background-color: $color-gray-lighten-75;
  font-family: "Nunito";
  font-size: 18px;
  color: $color-secondary-base;
}

.link {
  color: $color-primary-base;

  &:hover {
    color: darken($color-primary-base, 10%);
  }
}
