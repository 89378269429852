@import "../../scss/variables";

.input {
  width: 100%;
  height: 54px;
  line-height: 54px;
  padding: 0 $spacer-3;
  border: solid 2px $color-gray-lighten-75;
  background-color: $color-gray-lighten-75;
  border-radius: $spacer-2;
  outline: none;
  color: $color-gray-base;

  &:focus {
    border-color: $color-primary-base !important;
  }

  &:hover {
    border-color: $color-gray-lighten-50;
  }
}
